import { Box, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl, imagesDisabled } from 'utils'
import FieldValuesDrawer from './FieldValuesDrawer'
import FieldTypeJsonPreviewButton from 'components/FieldTypeJsonPreview'

const useStyles = makeStyles((theme) => ({
    selectPeopleArea: {
        background: `${theme?.palette.common.white} !important`,
        padding: '0.8rem',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        margin: '0 0 0.8rem 0',
        borderRadius: '0.4rem',
        minHeight: '4rem',
        scrollbarWidth: 'none',
        border: '0.1rem solid #3eaa05',
        '&:hover': {
            border: `0.1rem solid ${theme?.palette?.primary.main}`,
            background: '#efeff1 !important',
            '& .MuiBox-root': {
                background: '#efeff1 !important',
            },
        },
        '& .MuiBox-root': {
            background: `${theme?.palette.common.white} !important`,
            maxWidth: 'fit-content !important',
        },

        '& span': {
            fontSize: '1.2rem',
            color: `${theme?.palette.grey['900']} !important`,
            width: '17.5rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
        },
        '& p': {
            color: `${theme?.palette.grey['900']}`,
        },
        '& h2': {
            fontSize: '1.4rem !important',
            color: '#3b454d !important',
            fontWeight: 'normal',
            width: '19rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            userSelect: 'none',
            fontFamily: 'Rubik',
        },
        '& svg': {
            width: '1.12rem',
            height: '1.12rem',
        },
        '& .MuiAvatar-circular': {
            width: '1.6rem !important',
            height: '1.6rem !important',
            borderRadius: '50%',
            marginRight: '0.8rem',
        },
    },
    dimOpacity: {
        opacity: '0.75',
    },
    selectedPeopleArea: {
        background: `${theme?.palette.primary.main} !important`,

        '&:hover': {
            backgroundImage: `${theme?.palette.tertiary.gradient} !important`,
            '& .MuiBox-root': {
                background: 'transparent !important',
            },
        },
        '& .MuiBox-root': {
            background: `${theme?.palette.primary.main} !important`,
        },
        '& h2': {
            color: `${theme?.palette.common.white} !important`,
        },
        '& span': {
            color: `${theme?.palette.common.white} !important`,
        },
        '& p': {
            color: `${theme?.palette.common.white} !important`,
        },
        '& .MuiAvatar-circular': {
            width: '1.6rem !important',
            height: '1.6rem !important',
            borderRadius: '50%',
            marginRight: '0.8rem',
        },
    },
    disableMember: {
        background: '#f3f3f5 !important',
        cursor: 'default',
        '& .MuiBox-root': {
            background: '#f3f3f5 !important',
        },
        '& h2': {
            color: '#8b909a !important',
        },

        '&:hover': {
            background: '#f3f3f5 !important',
            '& .MuiBox-root': {
                background: '#f3f3f5 !important',
            },
        },
    },
    alreadyAddedMember: {
        background: '#f3f3f5 !important',
        cursor: 'default',
        '& .MuiBox-root': {
            background: '#f3f3f5 !important',
        },
        '& h2': {
            color: '#3b454d !important',
        },
        '& span': {
            color: `${theme?.palette.grey['900']} !important`,
        },
        '&:hover': {
            background: '#f3f3f5 !important',
            '& .MuiBox-root': {
                background: '#f3f3f5 !important',
            },
        },
    },
    editFieldTypes: {
        border: '0.1rem solid #3eaa05 !important',
    },
    requiredFieldTypes: {
        border: '0.1rem solid #d0021b !important',
    },
}))

const AssignmentItemWithFieldTypes = ({
    isSelectedAttribute,
    isLastSelectedItem,
    item,
    index,
    handleSelectPeople,
    handleDoubleClick = () => {
        return
    },
    secondaryAttributes,
    isInherited = false,
    showCrossIcon = false,
    showPlusIcon = false,
    primaryAttribute,
    fieldTypeProps = {},
    onFieldTypeSelection,
    tab,
    applicationId,
}) => {
    const classes = useStyles({})
    const { t } = useTranslation()
    const [openDrawer, setOpenDrawer] = useState(false)
    const theme = useTheme()

    const bindSecondaryText = () => {
        let secondaryText = ''

        secondaryAttributes.forEach((element) => {
            if (item[element]) {
                if (element === 'startDateUtc' || element === 'endDateUtc') {
                    let time = moment
                        .utc(item[element])
                        .clone()
                        .local()
                        .format('lll')
                    secondaryText = secondaryText
                        ? `${secondaryText} - ${time}`
                        : time
                } else {
                    secondaryText = secondaryText
                        ? `${secondaryText} - ${item[element]}`
                        : item[element]
                }
            }
        })
        return secondaryText
    }

    const bindSecondaryAttributes = () => {
        let secondaryComponent = (
            <Box marginTop={'2px'}>
                {secondaryAttributes.map((att) => {
                    return (
                        <>
                            {(att === 'startDateUtc' || att === 'endDateUtc') &&
                            moment(item[att]).isValid() ? (
                                <Box display="flex">
                                    <Box
                                        width="1.6rem"
                                        style={{ marginRight: '0.8rem' }}
                                    >
                                        <Icon
                                            name="Clock"
                                            color={
                                                isSelectedAttribute.isSelected
                                                    ? '#fff'
                                                    : '#8b909a'
                                            }
                                        />
                                    </Box>
                                    <Typography
                                        style={{
                                            fontSize: '1.2rem',
                                            marginRight: '0.4rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {att === 'startDateUtc'
                                            ? t('Starts') + ':'
                                            : t('Ends') + ':'}
                                    </Typography>
                                    <Typography variant="p">
                                        {moment
                                            .utc(item[att])
                                            .clone()
                                            .local()
                                            .format('lll')}
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    variant="p"
                                    style={{
                                        marginLeft: item?.hasOwnProperty(
                                            'imageThumbUrl',
                                        )
                                            ? '2.4rem'
                                            : 0,
                                        userSelect: 'none',
                                    }}
                                >
                                    {item[att]}
                                </Typography>
                            )}
                        </>
                    )
                })}
            </Box>
        )

        return secondaryComponent
    }

    const getTooltip = () => {
        if (isInherited) {
            return t('InheritedAssignmentMessage')
        }
        if (isSelectedAttribute?.alreadyAdded) {
            return t('AlreadyAdded')
        }
        if (isSelectedAttribute?.alreadyRemoved) {
            return t('AlreadyRemoved')
        }
    }
    const checkifDisabled = () =>
        isInherited ||
        isSelectedAttribute?.alreadyAdded ||
        isSelectedAttribute?.alreadyRemoved

    const isEditFieldTypes =
        (item?.fieldTypeObject?.itemToAdd?.cartObj?.length &&
            tab === 'added' &&
            item?.hasFieldTypes) ||
        tab === 'assigned'

    const getFieldTypeMessage = () => {
        if (isInherited) {
            return <></>
        }
        if (isEditFieldTypes) {
            return (
                <>
                    <Icon
                        name="EditFile"
                        style={{ width: '1.6rem', height: '1.6rem' }}
                        color={theme?.palette.primary.main}
                    />
                    <span>{t('EditFieldTypes')}</span>
                </>
            )
        } else if (item?.isRequiredFieldType) {
            return (
                <>
                    <Icon
                        name="Plus"
                        style={{ width: '1.6rem', height: '1.6rem' }}
                        color={theme?.palette.primary.main}
                    />
                    {t('AddFieldTypes')}
                    <span style={{ color: '#d0021b' }}>*</span>{' '}
                </>
            )
        }

        return (
            <>
                {item?.hasFieldTypes && (
                    <>
                        <Icon
                            name="Plus"
                            style={{ width: '1.6rem', height: '1.6rem' }}
                            color={theme?.palette.primary.main}
                        />
                        <span>{t('AddFieldTypes')}</span>
                    </>
                )}
            </>
        )
    }
    const getAssigneePersonObject = () => {
        return {
            ...fieldTypeProps?.assignee,
            assigneeId: fieldTypeProps?.assignee?.id,
            id: item?.id, //target assignee id
            assigneeResourceTypeId: fieldTypeProps?.assignee?.resourceTypeId,
            assigneeResourceTypeFriendlyName:
                fieldTypeProps?.assignee?.resourceTypeName,
            [fieldTypeProps?.keyForRoleRightId]:
                item?.[fieldTypeProps?.keyForRoleRightId],
            assignee: fieldTypeProps?.assignee?.friendlyName,
        }
    }
    return (
        <>
            <Box
                style={{ userSelect: 'none' }}
                className={classNames(classes.selectPeopleArea, {
                    [classes.selectedPeopleArea]:
                        isSelectedAttribute?.isSelected,
                    [classes.dimOpacity]:
                        tab === 'all' &&
                        isSelectedAttribute?.isSelected &&
                        !isLastSelectedItem,
                    [classes.alreadyAddedMember]:
                        isSelectedAttribute?.alreadyAdded ||
                        isSelectedAttribute?.alreadyRemoved,
                    [classes.disableMember]: isInherited,
                    [classes.requiredFieldTypes]:
                        item?.isRequiredFieldType &&
                        tab === 'added' &&
                        !isSelectedAttribute?.isSelected &&
                        !item?.fieldTypeObject?.itemToAdd?.cartObj?.length,
                    [classes.editFieldTypes]:
                        (tab === 'assigned' &&
                            !isSelectedAttribute?.alreadyRemoved) ||
                        (tab === 'added' &&
                            (item?.fieldTypeObject?.itemToAdd?.cartObj
                                ?.length ||
                                !item?.isRequiredFieldType) &&
                            !isSelectedAttribute?.isSelected &&
                            item?.hasFieldTypes),
                })}
                key={`search_item-${index}`}
                onClick={() => {
                    if (!checkifDisabled()) {
                        handleSelectPeople(item)
                    }
                }}
                onDoubleClick={(e) => {
                    if (!checkifDisabled()) {
                        handleDoubleClick(e, item)
                    }
                }}
            >
                <div
                    style={{
                        maxWidth: '19rem',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip
                        title={
                            <>
                                {checkifDisabled() ? (
                                    getTooltip()
                                ) : (
                                    <>
                                        <Typography
                                            style={{
                                                fontSize: '1.4rem',
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {item?.[primaryAttribute]}
                                        </Typography>
                                        {bindSecondaryText() && (
                                            <Typography
                                                style={{
                                                    fontSize: '1.2rem',
                                                }}
                                            >
                                                {bindSecondaryText()}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </>
                        }
                    >
                        <Box>
                            <Box display={'flex'}>
                                {item?.hasOwnProperty('imageThumbUrl') &&
                                    !imagesDisabled && (
                                        <Avatar
                                            size={'small'}
                                            src={getFullImageUrl(
                                                item?.imageThumbUrl,
                                            )}
                                        />
                                    )}

                                <Typography variant="h2">
                                    {item?.[primaryAttribute]}
                                </Typography>
                            </Box>
                            {bindSecondaryAttributes()
                                ? bindSecondaryAttributes()
                                : null}
                        </Box>
                    </Tooltip>
                </div>
                <Box>
                    {showCrossIcon ? (
                        <>
                            <Icon
                                name="SimpleUncheck"
                                color={
                                    isSelectedAttribute?.isSelected
                                        ? '#fff'
                                        : 'red'
                                }
                            />
                        </>
                    ) : (
                        <>
                            {(isSelectedAttribute?.alreadyAdded ||
                                isSelectedAttribute?.alreadyRemoved) &&
                                tab !== 'added' && (
                                    <Icon
                                        name="Check"
                                        color={
                                            isSelectedAttribute?.alreadyAdded ||
                                            isSelectedAttribute?.alreadyRemoved
                                                ? '#8b909a'
                                                : '#fff'
                                        }
                                    />
                                )}
                            {showPlusIcon && (
                                <Icon
                                    name="Plus"
                                    style={{
                                        width: '2rem',
                                        height: '2rem',
                                    }}
                                    color={
                                        isSelectedAttribute?.isSelected
                                            ? '#fff'
                                            : 'green'
                                    }
                                />
                            )}
                            {isInherited && (
                                <Icon
                                    name="Lock"
                                    style={{
                                        width: '1.6rem',
                                        height: '1.6rem',
                                    }}
                                    color={'#8B909A'}
                                />
                            )}
                            {tab === 'all' && isLastSelectedItem && (
                                <Box
                                    style={{
                                        height: '100%',
                                        alignContent: 'end',
                                    }}
                                >
                                    <Icon
                                        name="ArrowLong"
                                        style={{
                                            width: '1.6rem',
                                            height: '1.6rem',
                                        }}
                                        color={'#fff'}
                                        direction={'right'}
                                    />
                                </Box>
                            )}
                            {tab === 'all' &&
                                isSelectedAttribute?.isSelected &&
                                !isLastSelectedItem && (
                                    <Box
                                        style={{
                                            height: '100%',
                                            alignContent: 'end',
                                        }}
                                    >
                                        <Icon name="Check" color={'#fff'} />
                                    </Box>
                                )}
                        </>
                    )}
                </Box>
            </Box>
            {((tab === 'assigned' && !isSelectedAttribute?.alreadyRemoved) ||
                tab === 'added') && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            background: 'transparent !important',
                            cursor: 'pointer',
                            gap: '0.8rem',
                            color: theme?.palette.primary.main,
                            marginTop: '-0.4rem',
                            marginBottom: '1.2rem',
                        }}
                    >
                        <Box
                            onClick={() => {
                                setOpenDrawer(true)
                            }}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.8rem',
                                background: '#fff !important',
                                '& span': {
                                    fontSize: '1.3rem',
                                },
                            }}
                        >
                            {getFieldTypeMessage()}
                        </Box>

                        {tab === 'assigned' && (
                            <Box sx={{ background: '#fff !important' }}>
                                <FieldTypeJsonPreviewButton
                                    data={item}
                                    showLabel={true}
                                    withBorder={false}
                                />
                            </Box>
                        )}
                    </Box>
                    {openDrawer && (
                        <FieldValuesDrawer
                            openDrawer={openDrawer}
                            isEditFieldTypes={Boolean(isEditFieldTypes)}
                            assignee={getAssigneePersonObject()}
                            assignment={item}
                            isRole={fieldTypeProps.isRole}
                            keyForRoleRightId={
                                fieldTypeProps?.keyForRoleRightId
                            }
                            onClose={() => {
                                setOpenDrawer(false)
                            }}
                            existingFieldTypes={item?.fieldTypeObject}
                            applicationId={applicationId}
                            onSave={(fieldTypeItem) => {
                                if (
                                    onFieldTypeSelection &&
                                    fieldTypeItem?.length
                                ) {
                                    onFieldTypeSelection({
                                        tab: tab,
                                        itemToAdd: {
                                            localId:
                                                item?.[
                                                    fieldTypeProps
                                                        ?.keyForRoleRightId
                                                ],
                                            assignmentId: item?.id,
                                            cartObj: fieldTypeItem.map((f) => {
                                                return {
                                                    ...f,
                                                    targetAssigneeFriendlyName:
                                                        item?.friendlyName,
                                                }
                                            }),
                                        },
                                    })
                                }
                                setOpenDrawer(false)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default AssignmentItemWithFieldTypes

import { Box, Typography, styled, Dialog, useTheme } from '@mui/material'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { TextInput, Select } from 'packages/eid-ui'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import { useState } from 'react'
import { useCreateOrUpdatePBACAppResource } from 'hooks'
import { compareObjects } from 'utils'
import { EidAutocomplete } from 'packages/eid-controls'
import { imagesDisabled } from 'utils'
import config from 'config'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
    disableImage: imagesDisabled,
})

const queryParams = {
    take: 10,
}
export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
        cursor: 'pointer',
    },
})

const PopupModal = styled(Box)({
    maxWidth: '450px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

export const ModalFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '40px',
    '& button': {
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        padding: '4px 16px 4px 13px',
        width: '100%',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        '& svg': {
            marginLeft: '8px',
        },
        '&:nth-child(1)': {
            background: theme?.palette?.primary?.main,
            color: '#fff',
            width: 'auto',
        },
        '&:nth-child(2)': {
            color: theme?.palette?.primary?.main,
            marginLeft: '16px',
            maxWidth: '90px',
        },
    },
}))

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '450px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const AddResource = ({
    data,
    headerKey,
    applicationId,
    resourceSystemModuleId,
    resourceTypes,
    open = false,
    setOpenModal,
    loadingResourceTypes,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const isEditMode = Boolean(data?.id)
    const [selectedResourceType, setSelectedResourceType] = useState(null)

    const [resource, setResource] = useState({
        name: data?.name ?? '',
        friendlyName: data?.friendlyName ?? '',
        description: data?.description ?? '',
        owner: isEditMode
            ? { friendlyName: data.ownerFriendlyName, id: data.ownerAssigneeId }
            : null,
        id: data?.id ?? null,
    })
    const [createResource, { isLoading: creatingAppResources }] =
        useCreateOrUpdatePBACAppResource(
            ['LIST_PBAC_APP_RESOURCES', applicationId],
            isEditMode,
        )

    const handleSave = () => {
        const data = {
            systemModuleResourceTypeId: isEditMode
                ? resourceSystemModuleId
                : selectedResourceType?.id,
            ownerAssigneeId: resource?.owner?.id,
            ...resource,
        }
        !isEditMode && delete data.id
        delete data.owner
        createResource([data]).then(() => {
            handleModalClose()
        })
    }
    const handleModalClose = () => {
        setResource({ owner: null })
        setSelectedResourceType(null)
        setOpenModal(false)
    }

    const checkValidations = () => {
        if (isEditMode) {
            if (
                (compareObjects(resource, data, [
                    'name',
                    'friendlyName',
                    'description',
                ]).length !== 0 ||
                    resource?.owner?.id !== data?.ownerAssigneeId) &&
                resource.name &&
                resource.friendlyName &&
                resource.owner
            ) {
                return false
            } else {
                return true
            }
        } else if (
            resource.name &&
            resource.friendlyName &&
            resource.owner &&
            selectedResourceType
        ) {
            return false
        } else return true
    }

    return (
        <>
            {open && (
                <CustomDialog open={open} onClose={() => handleModalClose()}>
                    <PopupModal>
                        <CloseIconeBox onClick={() => handleModalClose()}>
                            <Icon
                                name="Close"
                                width="12px"
                                height="13px"
                                color="#959598"
                            />
                        </CloseIconeBox>

                        <Typography variant="h2">{t(headerKey)}</Typography>

                        <Box marginTop={'10px'}>
                            <Typography variant="subtitle1">
                                {t('Name')}
                            </Typography>
                            <TextInput
                                placeholder={t('Name')}
                                value={resource.name}
                                handleChange={(value) =>
                                    setResource({
                                        ...resource,
                                        name: value,
                                    })
                                }
                            />
                        </Box>

                        <Box marginTop={'10px'}>
                            <Typography variant="subtitle1">
                                {t('DisplayName')}
                            </Typography>
                            <TextInput
                                placeholder={t('DisplayName')}
                                value={resource.friendlyName}
                                handleChange={(value) =>
                                    setResource({
                                        ...resource,
                                        friendlyName: value,
                                    })
                                }
                            />
                        </Box>

                        {!isEditMode && (
                            <Box marginTop={'10px'}>
                                <Typography variant="subtitle1">
                                    {t('PBACResourceType')}
                                </Typography>

                                <Select
                                    options={resourceTypes ?? []}
                                    onChange={(data) => {
                                        setSelectedResourceType(data)
                                    }}
                                    value={selectedResourceType}
                                    getOptionLabel={(option) =>
                                        option.friendlyName
                                    }
                                    isLoading={loadingResourceTypes}
                                    placeholder={t('SelectType')}
                                />
                            </Box>
                        )}

                        <Box marginTop={'10px'}>
                            <Typography variant="subtitle1">
                                {t('Owner')}
                            </Typography>
                            <EidAutocomplete.New
                                placeholder={t('SelectPerson')}
                                url={'api/people'}
                                queryParams={queryParams}
                                dedupingInterval={60000}
                                value={resource.owner}
                                getOptionLabel={(option) => option.friendlyName}
                                endAdornmentComponent={() => (
                                    <ArrowIcon
                                        direction="down"
                                        color="#aab0b4"
                                    />
                                )}
                                optionComponent={OptionComponent}
                                onChange={(_, data) => {
                                    setResource({
                                        ...resource,
                                        owner: data,
                                    })
                                }}
                                inputWrapperStyleProps={{ fontSize: '16px' }}
                            />
                        </Box>

                        <Box marginTop={'10px'}>
                            <Typography variant="subtitle1">
                                {t('Description')}
                            </Typography>
                            <TextInput
                                placeholder={t('Description')}
                                value={resource.description}
                                handleChange={(e) =>
                                    setResource({
                                        ...resource,
                                        description: e,
                                    })
                                }
                            />
                        </Box>

                        <ModalFooter>
                            <Button
                                loading={creatingAppResources}
                                onClick={() => {
                                    handleSave()
                                }}
                                style={{
                                    height: '32px !important',
                                    overflowWrap: 'anywhere',
                                }}
                                backgroundColor={theme?.palette?.primary?.main}
                                disabledStyles={{
                                    backgroundColor: '#aab0b4 !important',
                                    color: '#ffffff !important',
                                    borderColor: '#aab0b4 !important',
                                }}
                                disabled={checkValidations()}
                            >
                                {isEditMode
                                    ? t('Save')
                                    : t('AddNewPbacResources')}
                            </Button>
                            <Button
                                style={{
                                    height: '32px !important',
                                }}
                                onClick={() => handleModalClose()}
                            >
                                {t('Cancel')}
                            </Button>
                        </ModalFooter>
                    </PopupModal>
                </CustomDialog>
            )}
        </>
    )
}

export default AddResource

import { Fragment, useEffect, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Paper, styled } from '@mui/material'
import { useApplication } from 'hooks'
import {
    getFullImageUrl,
    getAccessibleTabs,
    imagesDisabled,
    isApplicationAzureApp,
    isApplicationPbacApp,
    isApplicationPbacAppHavingAppResources,
    updateUrlQueryParams,
    RESOURCE_TYPE_NAMES,
    isApplicationPbacAppHavingFieldTypes,
    isApplicationAzureAppWithPbac,
} from 'utils'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { Overview } from './Overview/Overview'
import ApplicationCertificates from 'components/ApplicationBanner/Application Certificates/ApplicationCertificates'
import { LeftPaneTabs, LeftPaneActions } from 'components'
import ApplicationSecrets from './Application Secrets/ApplicationSecrets'
import SecretsDrawer from './Application Secrets/SecretsDrawer'
import { useHistory } from 'react-router'
import CertificatesDrawer from './Application Certificates/CertificatesDrawer'
import { PageContextProvider } from 'pageContext'
import ApplicationScopes from './Application Scopes/ApplicationScopes'
import ScopesDrawer from './Application Scopes/ScopesDrawer'
import AppRightsDrawer from './AzureApplicationAppRights/AppRightsDrawer'
import ApplicationAppRights from './AzureApplicationAppRights'
import ApplicationPermissions from './API Permissions/Permissions'
import PermissionsDrawer from './API Permissions/PermissionsDrawer'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { IAmShopAssignments } from './IAMShopAssignments/IAmShopAssignments'
import { IAmShopAssignees } from './IAMShopAssignees/IAmShopAssignees'
import { TokenConfiguration } from './Token Configurations'
import ApplicationManagementRoles from './Applications Management Roles'
import AppManagementRoleDrawer from './Applications Management Roles/App Management Roles/Details/AppManagementRolesDrawer'
import RoleDefinitions from './RoleDefinitions'
import RoleDefinitionDrawer from './RoleDefinitions/Details/RoleDefinitionDrawer'
import useSubcomponents from 'useSubcomponents'
import PbacAccessAssignments from './PbacAccessAssignments'
import PbacAppResources from './PbacAppResources'
import PbacDefinitinos from './PbacDefinitions'
import { Skeleton } from '@mui/material'
import { AccessRequestPolicy } from 'components/AccessRequestPolicy/AccessRequestPolicy'
import { DirectAssignedLocations } from './DirectAssignedLocations'
import { AccessManagers } from './AccessManagers(Owners)'
import { useAppState } from 'appContext'
import FieldTypeDrawer from './PbacDefinitions/AppOwnedFieldTypes/AppOwnedFieldTypeDetails/FieldTypeDrawer'

const smallScreenWidth = 960
const TAB_VERBS = {
    AZURE_APPS: 'AzureApps',
    PBAC_APPS: 'Apps',
}
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            minHeight: '125px',
            borderRadius: '8px',
            marginBottom: '20px',

            '& img': {
                maxHeight: '80px',
                maxWidth: '80px',
            },

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '116px',
                '& img': {
                    maxHeight: '33px',
                    width: '66px',
                },
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
            wordBreak: 'break-word',
            padding: '5px',
        },

        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            minWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: theme?.palette?.primary?.main,
                    color: 'white',
                    '& span': {
                        color: theme.palette.common.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.common.white,

                border: `solid 1px ${theme.palette.grey[900]}`,

                '&:hover': {
                    backgroundColor: theme.palette.grey[900],

                    '& span': {
                        //  color: theme.palette.common.white,
                    },
                },
            },
        },
        actionDropdown: {
            marginTop: '40px',
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },

            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: theme?.palette?.primary?.main,
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: theme?.palette?.primary?.main,
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: theme?.palette?.primary?.main,
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
    }),
)

export const TabsColumn = styled(Box)({
    display: 'flex',
    minWidth: '340px',
    flexDirection: 'column',
    margin: '0px 12px 0px 10px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

export const ApplicationBanner = (props: any) => {
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()
    const { t } = useTranslation()
    const { applicationId } = props
    const { data, refetch } = useApplication(applicationId)
    const query = useQuery()
    const [, dispatch]: any = useAppState()

    const secretId = query.get('secretId')
    const certificateId = query.get('certificateId')
    const scopeId = query.get('scopeId')
    const appRoleId = query.get('appRoleId')
    const permissionId = query.get('permissionId')
    const managementRoleId = query.get('appManagementRoleId')
    const roleDefinitionId = query.get('roleDefinitionId')
    const fieldTypeId = query.get('fieldTypeId')
    const history = useHistory()

    const isAzureApplication = isApplicationAzureApp(data?.applicationType)

    const isPBACApplication = isApplicationPbacApp(data?.applicationType)

    const isAzureAppWithPbac = isApplicationAzureAppWithPbac(
        data?.applicationType,
    )

    const isBuiltInSystemApp = data?.isBuiltInSystemApp

    const isPBACApplicationWithResources =
        isApplicationPbacAppHavingAppResources(data?.applicationType)
    const isApplicationPbacAppWithFieldTypes =
        isApplicationPbacAppHavingFieldTypes(data?.applicationType)

    useEffect(() => {
        if (data?.id) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: applicationId,
                    targetAssigneeFriendlyName:
                        data?.friendlyName ??
                        t(RESOURCE_TYPE_NAMES.APPLICATIONS),
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.APPLICATIONS,
                    ),
                },
            })

            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: applicationId,
                    targetAssigneeFriendlyName:
                        data?.friendlyName ??
                        t(RESOURCE_TYPE_NAMES.APPLICATIONS),
                    targetAssignee_ResourceTypeFriendlyName: t(
                        RESOURCE_TYPE_NAMES.APPLICATIONS,
                    ),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const allTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
            default: true,
        },
        {
            label: t('ClientSecrets'),
            value: 'secrets',
            icon: <Icon name="ClientSecret" />,
            verb: TAB_VERBS.AZURE_APPS,
            noun: 'ResourceAdminSecrets',
            display:
                (isAzureApplication || isAzureAppWithPbac) &&
                !isBuiltInSystemApp,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-ClientSecrets',
            },
        },
        {
            label: t('ClientCertificates'),
            value: 'certificates',
            icon: <Icon name="ClientCertificate" />,
            verb: TAB_VERBS.AZURE_APPS,
            noun: 'ResourceAdminCertificates',
            display:
                (isAzureApplication || isAzureAppWithPbac) &&
                !isBuiltInSystemApp,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-ClientCertificates',
            },
        },
        {
            label: t('Scopes'),
            value: 'scopes',
            icon: <Icon name="Scope" />,
            display: isAzureApplication && !isBuiltInSystemApp,
            verb: TAB_VERBS.AZURE_APPS,
            noun: 'ResourceAdminScopes',
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-Scopes',
            },
        },

        {
            label: t('ApiPermissions'),
            value: 'permissions',
            icon: <Icon name="ApiPermission" />,
            display:
                (isAzureApplication || isAzureAppWithPbac) &&
                !isBuiltInSystemApp,
            verb: TAB_VERBS.AZURE_APPS,
            noun: 'ResourceAdminPermissions',
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-APIPermissions',
            },
        },
        {
            label: t('TokenConfigurations'),
            value: 'tokens',
            icon: <Icon name="Claims" />,
            display:
                (isAzureApplication || isAzureAppWithPbac) &&
                !isBuiltInSystemApp,
            verb: TAB_VERBS.AZURE_APPS,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-TokenConfigurations',
            },
            subTabs: [
                {
                    label: t('ClaimsMappingPolicy'),
                    value: 'claims',
                    display: true,
                    noun: 'ResourceAdminTokens',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Applications-Details-Claims-Mapping-Policy',
                    },
                },
                {
                    label: t('OptionalClaims'),
                    value: 'optionalClaims',
                    display: true,
                    noun: 'ResourceAdminTokens',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Applications-Details-Optional-Claims',
                    },
                },
            ],
        },
        {
            label: t('IAmShopAssignments'),
            value: 'assignments',
            icon: <Icon name="AzureRbacRolesSmall" />,
            display: false, //NOTE : Has been removed from application details. Keeping code for record
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-IAMShopAssignments',
            },
        },
        {
            label: t('IAmShopAssignees'),
            value: 'assignees',
            icon: <Icon name="ResultantMembers" />,
            display: false, //NOTE : Has been removed from application details. Keeping code for record
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-IAMShopAssignees',
            },
        },
        {
            label: t('AzureApplicationRoles'),
            value: 'roles',
            icon: <Icon name="AppRole" />,
            display: isAzureApplication || isAzureAppWithPbac,
            verb: TAB_VERBS.AZURE_APPS,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-AzureApplicationRoles',
            },
            subTabs: [
                {
                    label: t('AppRights'),
                    value: 'appRights',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppRights-Control',
                    },
                    noun: 'ResourceAdminRoles',
                    display: true,
                },
                {
                    label: t('Assignments'),
                    value: 'assignments',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppRights-Assignments-Control',
                    },
                    noun: 'ResourceAdminRoles',
                    display: data?.allowAppRoleDirectAssignment,
                },
                {
                    label: t('People'),
                    value: 'people',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppRights-People-Control',
                    },
                    noun: 'ResourceAdminRoles',
                    display: data?.allowAppRoleDirectAssignment,
                },
            ],
        },
        {
            label: t('RoleDefinitions'),
            value: 'roleDefinitions',
            icon: <Icon name="RoleDefinition" />,
            display: isAzureApplication,
            verb: TAB_VERBS.AZURE_APPS,

            requireAccess: {
                control: 'ResourceAdmin-Application-Details-RoleDefinitions',
            },
            subTabs: [
                {
                    label: t('RoleDefinitions'),
                    value: 'roleDefinitions',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-RoleDefinitions-Control',
                    },
                    display: true,
                    noun: 'ResourceAdminRoleDefinitions',
                },
                {
                    label: t('Assignments'),
                    value: 'assignments',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-RoleDefinitions-Assignments-Control',
                    },
                    display: data?.allowRoleDefinitionDirectAssignment,
                    noun: 'ResourceAdminRoleDefinitions',
                },
                {
                    label: t('People'),
                    value: 'people',
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-RoleDefinitions-People-Control',
                    },
                    display: data?.allowRoleDefinitionDirectAssignment,
                    noun: 'ResourceAdminRoleDefinitions',
                },
            ],
        },
        {
            label: t('AppManagementRoles'),
            value: 'appManagementRoles',
            icon: <Icon name="ManagementRolesSmall" />,
            display: isAzureApplication || isAzureAppWithPbac,
            verb: TAB_VERBS.AZURE_APPS,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-AppManagementRoles',
            },
            subTabs: [
                {
                    label: t('AppManagementRoles'),
                    value: 'appManagementRoles',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppManagementRoles-Control',
                    },
                    noun: 'ResourceAdminAppManagementRoles',
                },
                {
                    label: t('PeopleAsMembers'),
                    value: 'members',
                    display: data?.allowAppManagementRoleDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppManagementRoles-Member-Control',
                    },
                    noun: 'ResourceAdminAppManagementRoles',
                },
                {
                    label: t('DirectAccessGranted'),
                    value: 'directAccessGranted',
                    display: data?.allowAppManagementRoleDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppManagementRoles-DirectAccessGranted-Control',
                    },
                    noun: 'ResourceAdminAppManagementRoles',
                },
                {
                    label: t('TotalAccessGranted'),
                    value: 'accessGranted',
                    display: data?.allowAppManagementRoleDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-AppManagementRoles-TotalAccessGranted-Control',
                    },
                    noun: 'ResourceAdminAppManagementRoles',
                },
            ],
        },
        {
            label: t('PbacAccessAssignments'),
            value: 'pbacAccessAssignments',
            icon: <Icon name="PbacAccessAssignments" />,
            display: isPBACApplication || isAzureAppWithPbac,
            verb: TAB_VERBS.PBAC_APPS,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-PbacAccessAssignments',
            },
            subTabs: [
                {
                    label: t('AppRightsAssignments'),
                    value: 'appRightsAssignments',
                    display: data?.allowAppRoleDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacAccessAssignments-AppRightsAssignments',
                    },
                    noun: 'ResourceAdminPbacAccessAssignments',
                },
                {
                    label: t('RoleDefinitionAssignments'),
                    value: 'roleDefinitionAssignments',
                    display: data?.allowRoleDefinitionDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacAccessAssignments-RoleDefinitionAssignments',
                    },
                    noun: 'ResourceAdminPbacAccessAssignments',
                },
                {
                    label: t('AppRightsPeople'),
                    value: 'appRightsPeople',
                    display: data?.allowAppRoleDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacAccessAssignments-AppRightsPeople',
                    },
                    noun: 'ResourceAdminPbacAccessAssignments',
                },
                {
                    label: t('RoleDefinitionPeople'),
                    value: 'roleDefinitionPeople',
                    display: data?.allowRoleDefinitionDirectAssignment,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacAccessAssignments-RoleDefinitionPeople',
                    },
                    noun: 'ResourceAdminPbacAccessAssignments',
                },
            ],
        },
        {
            label: t('PbacDefinitions'),
            value: 'pbacDefinitions',
            icon: <Icon name="PbacDefinitions" />,
            display: isPBACApplication || isAzureAppWithPbac,
            verb: TAB_VERBS.PBAC_APPS,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-PbacDefinitions',
            },
            subTabs: [
                {
                    label: t('AppRights'),
                    value: 'appRights',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacDefinitions-AppRights',
                    },
                    noun: 'ResourceAdminPbacDefinitionsAppRights',
                },
                {
                    label: t('RoleDefinitions'),
                    value: 'roleDefinitions',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacDefinitions-RoleDefinitions',
                    },
                    noun: 'ResourceAdminPbacDefinitionsRoleDefinitions',
                },
                {
                    label: t('AppManagementRoles'),
                    value: 'appManagementRoles',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacDefinitions-AppManagementRoles',
                    },
                    noun: 'ResourceAdminPbacDefinitionsAppManagementRoles',
                },
                {
                    label: t('PbacResourceTypes'),
                    value: 'pbacResourceTypes',
                    display: true,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacDefinitions-PbacResourceTypes',
                    },
                    noun: 'ResourceAdminPbacDefinitionsResourceTypes',
                },
                {
                    label: t('AppOwnedFieldTypes'),
                    value: 'appOwnedFieldTypes',
                    display:
                        isApplicationPbacAppWithFieldTypes ||
                        isAzureAppWithPbac,
                    requireAccess: {
                        control:
                            'ResourceAdmin-Application-Details-PbacDefinitions-AppOwnedFieldTypes',
                    },
                    noun: 'ResourceAdminPbacDefinitionsAppOwnedFieldTypes',
                },
            ],
        },
        {
            label: t('PbacAppResources'),
            value: 'pbacAppResources',
            icon: <Icon name="PbacAppResources" />,
            display: isPBACApplicationWithResources,
            verb: TAB_VERBS.PBAC_APPS,
            noun: 'ResourceAdminPbacAppResources',
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-PbacAppResources',
            },
        },
        {
            label: t('AccessManagers(Owners)'),
            value: 'accessManagers',
            icon: <Icon name="ExternalCredential" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-Overview-AccessManagers',
            },
            noun: 'ResourceAdminAccessManagers',
        },
        {
            label: t('DirectAssignedLocations'),
            value: 'directAssignedLocations',
            icon: <Icon name="Claims" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-DirectAssignedLocations',
            },
            noun: 'ResourceAdminDirectAssignedLocations',
        },
        {
            label: t('AccessRequestPolicy'),
            value: 'accessRequestPolicy',
            icon: <Icon name="ClientCertificate" />,
            display: true,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-AccessRequestPolicy',
            },
            noun: 'ResourceAdminAccessRequestPolicy',
        },
    ]
    const [activeTab, setActiveTab] = useState(null as any)
    const [subActiveTab, setSubActiveTab] = useState(null as any)
    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])

    const activeTabObj = allTabs?.find((t) => t.value === activeTab)
    const subActiveTabObj = activeTabObj?.subTabs?.find(
        (t) => t.value === subActiveTab,
    )

    useEffect(() => {
        if (data) {
            const queryActiveTab = query.get('activeTab')
            const queryActiveSubTab = query.get('subActiveTab')
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                allTabs,
                queryActiveTab,
                queryActiveSubTab,
                hasAccessToControl,
                'activeTab',
                'subActiveTab',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setSubActiveTab(activeTabInfo.querySubActiveTab)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleTabChange = (tab: any) => {
        const tabObj = allTabs.filter((t) => t.value === tab)[0]
        let defaultSubTab = null

        if (tabObj && tabObj.subTabs) {
            const allowedSubTabs = tabObj.subTabs.filter(
                (x) =>
                    !x?.requireAccess?.control ||
                    (hasAccessToControl(x?.requireAccess?.control) &&
                        x.display),
            )

            if (allowedSubTabs && allowedSubTabs.length > 0)
                defaultSubTab = allowedSubTabs[0].value
        }

        handleSubTabChange(defaultSubTab)
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'activeTab', value: tab }],
            ['returnedFromWorkflow'],
        )
    }
    const handleSubTabChange = (subTab: any) => {
        if (subTab) {
            setSubActiveTab(subTab)
            updateUrlQueryParams(
                query,
                history,
                [{ key: 'subActiveTab', value: subTab }],
                ['returnedFromWorkflow'],
            )
        } else {
            setSubActiveTab(null)
            updateUrlQueryParams(
                query,
                history,
                [],
                ['subActiveTab', 'returnedFromWorkflow'],
            )
        }
    }

    const checkSubTabAccess = (tabValue: string, subTabValue: string) => {
        const filteredTab = allTabs.find(
            (t) => t.value.toLowerCase() === tabValue.toLowerCase(),
        )
        let filteredSubTab = null
        if (filteredTab) {
            if (subActiveTab) {
                filteredSubTab = filteredTab.subTabs?.find(
                    (t) => t.value.toLowerCase() === subTabValue.toLowerCase(),
                )
            }

            return filteredSubTab
                ? hasAccessToControl(filteredSubTab.requireAccess?.control)
                : filteredTab.subTabs?.find((t) =>
                      hasAccessToControl(t.requireAccess?.control),
                  )
        }
        return false
    }
    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        {data?.friendlyName}
                    </Paper>
                    {!imagesDisabled && (
                        <Paper className={classes.imgContainer}>
                            <img
                                alt={data?.friendlyName}
                                src={
                                    isNilOrEmpty(data?.imageUrl)
                                        ? getFullImageUrl(
                                              '/webcdn/Images/AppLogos/Genric-1.png',
                                          )
                                        : getFullImageUrl(
                                              data?.applicationLogoImageUrl,
                                          )
                                }
                            />
                        </Paper>
                    )}
                    <Paper className={classes.tabsContainer}>
                        <LeftPaneTabs
                            active={activeTab}
                            subActiveTab={subActiveTab}
                            onChange={handleTabChange}
                            tabs={allAccessibleTabs}
                            onSubTabChange={handleSubTabChange}
                        />
                    </Paper>
                    <LeftPaneActions
                        resource={data}
                        resourceType="applications"
                        activeTab={activeTab}
                        activeTabLabel={activeTabObj?.label}
                        tabVerb={activeTabObj?.verb}
                        tabNoun={
                            subActiveTabObj
                                ? subActiveTabObj?.noun
                                : activeTabObj?.noun
                        }
                    />
                </TabsColumn>
                <Box className={classes.root}>
                    {activeTab === 'overview' && (
                        <PageContextProvider key="RequestViewApplicationOverview">
                            <Paper>
                                <Overview applicationId={applicationId} />
                            </Paper>
                        </PageContextProvider>
                    )}
                    {activeTab === 'secrets' && (
                        <PageContextProvider key="RequestViewSecrets">
                            {!data?.azureApplicationId ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <ApplicationSecrets
                                    azureApplicationId={
                                        data?.azureApplicationId
                                    }
                                    resourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'certificates' && (
                        <PageContextProvider key="RequestViewCertificates">
                            {!data?.azureApplicationId ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <ApplicationCertificates
                                    azureApplicationId={
                                        data?.azureApplicationId
                                    }
                                    resourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'scopes' && (
                        <PageContextProvider key="RequestViewScopes">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <ApplicationScopes
                                    id={data?.id}
                                    resourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'roles' && (
                        <PageContextProvider key="RequestViewAppRights">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <ApplicationAppRights
                                    applicationId={data?.id}
                                    resourceId={data?.resourceId}
                                    subActiveTab={
                                        checkSubTabAccess(
                                            'roles',
                                            subActiveTab,
                                        ) && subActiveTab
                                    }
                                    resourceSystemModuleID={
                                        data?.resourceSystemModuleID
                                    }
                                    applicationFriendlyName={data?.friendlyName}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'permissions' && (
                        <PageContextProvider key="RequestViewPermissions">
                            {!data?.azureApplicationId ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <ApplicationPermissions
                                    id={data?.azureApplicationId}
                                    resourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'assignments' && (
                        <PageContextProvider key="RequestViewAssignments">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <IAmShopAssignments id={data?.id} />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'assignees' && (
                        <PageContextProvider key="RequestViewAssignees">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <IAmShopAssignees id={data?.id} />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'tokens' && (
                        <PageContextProvider key="RequestViewClaims">
                            {!data?.azureApplicationId ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <TokenConfiguration
                                    id={data?.azureApplicationId}
                                    subActiveTab={
                                        checkSubTabAccess(
                                            'tokens',
                                            subActiveTab,
                                        ) && subActiveTab
                                    }
                                    applicationResourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'roleDefinitions' && (
                        <PageContextProvider key="RequestViewRoleDefinitions">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <RoleDefinitions
                                    resourceSystemModuleId={
                                        data?.resourceSystemModuleID
                                    }
                                    applicationId={data?.id}
                                    subActiveTab={
                                        checkSubTabAccess(
                                            'roleDefinitions',
                                            subActiveTab,
                                        ) && subActiveTab
                                    }
                                    applicationFriendlyName={data?.friendlyName}
                                    applicationType={data?.applicationType}
                                    resourceId={data?.resourceId}
                                    applicationResourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'appManagementRoles' && (
                        <PageContextProvider key="RequestViewAppManagementRoles">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <ApplicationManagementRoles
                                    id={data?.azureApplicationId}
                                    applicationId={data?.id}
                                    subActiveTab={
                                        checkSubTabAccess(
                                            'appManagementRoles', //checking access here just to stop API call to be made for subtabs
                                            subActiveTab,
                                        ) && subActiveTab
                                    }
                                    applicationResourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'pbacAccessAssignments' && (
                        <PageContextProvider key="RequestViewPbacAccessAssignments">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <PbacAccessAssignments
                                    subActiveTab={
                                        checkSubTabAccess(
                                            'pbacAccessAssignments',
                                            subActiveTab,
                                        ) && subActiveTab
                                    }
                                    applicationId={data?.id}
                                    resourceSystemModuleID={
                                        data?.resourceSystemModuleID
                                    }
                                    applicationFriendlyName={data?.friendlyName}
                                    applicationType={data?.applicationType}
                                    resourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'pbacDefinitions' && (
                        <PageContextProvider key="RequestViewPbacDefinitinos">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <PbacDefinitinos
                                    subActiveTab={
                                        checkSubTabAccess(
                                            'pbacDefinitions',
                                            subActiveTab,
                                        ) && subActiveTab
                                    }
                                    applicationId={data?.id}
                                    resourceSystemModuleId={
                                        data?.resourceSystemModuleID
                                    }
                                    id={data?.id}
                                    resourceId={data?.resourceId}
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'pbacAppResources' && (
                        <PageContextProvider key="RequestViewPbacAppResources">
                            {!data?.id ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : (
                                <PbacAppResources
                                    applicationId={data?.id}
                                    resourceSystemModuleId={
                                        data?.resourceSystemModuleID
                                    }
                                />
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'accessManagers' && (
                        <PageContextProvider key="RequestViewApplicationsAccessManagers">
                            <AccessManagers resource={data} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'directAssignedLocations' && (
                        <PageContextProvider key="DirectAssignedLocations">
                            <DirectAssignedLocations id={data?.id} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'accessRequestPolicy' && (
                        <PageContextProvider key="AccessRequestPolicy">
                            <AccessRequestPolicy
                                resourceId={data?.id}
                                requestPolicyId={data?.requestPolicyId}
                                refetch={refetch}
                            />
                        </PageContextProvider>
                    )}
                </Box>
            </Box>
            {secretId && (
                <PageContextProvider key="RequestViewSecrets">
                    <SecretsDrawer
                        id={secretId}
                        onClose={() => {
                            query.delete('secretId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {certificateId && (
                <PageContextProvider key="RequestViewCertificates">
                    <CertificatesDrawer
                        id={certificateId}
                        onClose={() => {
                            query.delete('certificateId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {scopeId && (
                <PageContextProvider key="RequestViewScopes">
                    <ScopesDrawer
                        id={scopeId}
                        onClose={() => {
                            query.delete('scopeId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {appRoleId && (
                <PageContextProvider key="RequestViewAppRights">
                    <AppRightsDrawer
                        id={appRoleId}
                        allowAppRoleDirectAssignment={
                            data?.allowAppRoleDirectAssignment
                        }
                        applicationType={data?.applicationType}
                        resourceId={data?.resourceId}
                        onClose={() => {
                            query.delete('appRoleId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        resourceSystemModuleID={data?.resourceSystemModuleID}
                    />
                </PageContextProvider>
            )}
            {permissionId && (
                <PageContextProvider key="RequestViewPermissions">
                    <PermissionsDrawer
                        id={permissionId}
                        onClose={() => {
                            query.delete('permissionId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {managementRoleId && (
                <PageContextProvider key="RequestViewAppManagementRoles">
                    <AppManagementRoleDrawer
                        id={managementRoleId}
                        applicationId={applicationId}
                        applicationResourceId={data?.resourceId}
                        allowAppManagementRoleDirectAssignment={
                            data?.allowAppManagementRoleDirectAssignment
                        }
                        onClose={() => {
                            query.delete('appManagementRoleId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
            {roleDefinitionId && (
                <PageContextProvider key="RequestViewRoleDefinitions">
                    <RoleDefinitionDrawer
                        id={roleDefinitionId}
                        applicationId={applicationId}
                        allowRoleDefinitionDirectAssignment={
                            data?.allowRoleDefinitionDirectAssignment
                        }
                        applicationType={data?.applicationType}
                        isCrossAzLocalRightEnabled={
                            data?.isCrossAzLocalRightEnabled
                        }
                        resourceId={data?.resourceId}
                        onClose={() => {
                            query.delete('roleDefinitionId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        resourceSystemModuleID={data?.resourceSystemModuleID}
                    />
                </PageContextProvider>
            )}
            {fieldTypeId && (
                <PageContextProvider key="RequestViewFieldTypes">
                    <FieldTypeDrawer
                        id={fieldTypeId}
                        applicationId={applicationId}
                        onClose={() => {
                            query.delete('fieldTypeId')
                            query.delete('drawerActiveTab')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}
        </>
    )
}
